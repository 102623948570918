import hero from "../images/support_Hero.png";
import sc_logo_desk from "../images/logo_red.png";
import sc_logo_two from "../images/logo_maternity_mates.png";
import support_hero from "../images/support_maternity.png";
import support_vid from "../images/support_maternityVid.png";
import support_refer from "../images/support_referSomeone.png";
import support_femaleGC from "../images/support_femaleGC.png";
import support_healing from "../images/support_healing.png";
import support_peer from "../images/support_peer.png";
import support_womenConvo from "../images/support_womenConvo.png";
import Header from "../sections/Header.js";
import Footer from "../sections/Footer.js";
import { useRef } from "react";

const Support = () => {
  const supportPageRef = useRef();
  const maternityComponent = useRef();
  const femaleComponent = useRef();
  const healingComponent = useRef();
  const peerComponent = useRef();
  const womensComponent = useRef();

  const arrowScroll = (whichContainer) => {
    whichContainer.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="">
      <Header whichRef={supportPageRef} whichColor={"#ffe1d9"} />
      {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------OPENING Get Support content--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#eab1fc]">
        <div className="mx-auto max-w-[1240px]">
          <a href="/" className="0">
            <img
              src={sc_logo_desk}
              className="mt-[1rem] absolute w-[90px] z-[21]"
              alt="secret-circle-logo"
            />
          </a>
          <div className="flex  md:flex-row items-center w-full bg-[#eab1fc] overflow-hidden">
            <div className="max-w-[1240px]  absolute md:mb-0 md:mt-0 w-[100%] ">
              <h1 className="xs:z-10 xs:relative md:static md:ml-0 xs:text-left md:text-center xs:mb-0  xs:mr-0  xs:ml-[2rem] s:ml-[3rem] sm:ml-[4rem]  md:mt-0  font-sans antialiased mds:text-[4.8rem] mds:leading-[2.2rem] s:text-[3rem] xss:text-[2.8rem] xss:leading-[2.2rem] align-middle text-[#b24029]">
                Get support
              </h1>
              <p className="hidden md:block xs:z-10  h-[100%] bottom-[-526%] absolute mx-auto w-[100%] text-center xs:mb-0     md:mt-0  font-sans antialiased mds:text-[0.83rem] md:text-[0.7rem] mds:leading-[1.2rem] s:text-[0.65rem] xss:text-[0.6rem] xss:leading-[1.2rem] align-middle text-[#b24029]">
                <span>
                  <button
                    className="underline underline-offset-3"
                    onClick={() => arrowScroll(maternityComponent)}
                  >
                    Maternity Mates
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <button
                    className="underline underline-offset-3"
                    onClick={() => arrowScroll(femaleComponent)}
                  >
                    Female Genital Cutting Support
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <button
                    className="underline underline-offset-3"
                    onClick={() => arrowScroll(healingComponent)}
                  >
                    Healing Conversations
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <button
                    className="underline underline-offset-3"
                    onClick={() => arrowScroll(peerComponent)}
                  >
                    Group Peer Support
                  </button>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <button
                    className="underline underline-offset-3"
                    onClick={() => arrowScroll(womensComponent)}
                  >
                    Women’s Conversation Cafe
                  </button>
                </span>
              </p>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}

            <div className=" md:mt-0  md:mb-0 w-[100%]  ">
              <img
                src={hero}
                className=" xs:relative md:static ss:left-[20%] xs:left-[15%] md:left-0  xs:mx-auto md:min-h-[388px] md:max-h-[100vh] ss:h-[100%] xs:h-[188px] ss:mt-0 xs:mt-[18%] hero-img float-right"
                alt="hero"
              />
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------Maternity Mates--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={supportPageRef} className="bg-[#ffe1d9]">
        <div
          ref={maternityComponent}
          className="mx-auto max-w-[1240px] xs:pt-[0vh] md:pt-[9rem] md:pb-[10vh]"
        >
          <div className="flex xs:flex-col-reverse xs:items-center md:items-start md:flex-row w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden">
            {/* <div className="md:mb-[18vh] md:ml-7 md:mt-[18vh] xs:mb-[20vh] xs:mt-[5vh] xs:w-4/5 md:w-6/12 ">  */}
            <div className="   xs:mb-[5vh] xs:mt-[5vh] md:mt-0  md:mb-0 xs:w-full md:w-[65%] ">
              <img
                src={support_hero}
                className=" xs:mx-auto max-h-[671px] hidden md:block "
                alt="maternity 1"
              />
              <img
                src={support_vid}
                className=" md:pl-[34px] xs:pl-0 xs:mx-auto md:ml-7 md:mr-0 md:mt-[10rem] xs:mt-0 max-h-[366px] md:float-right"
                alt="video"
              />
              {/* <p className=" md:pl-[34px] xs:pl-0 xs:mt-1 xs:mx-auto xs:w-[80%] md:w-[auto] md:mt-0 md:mr-0 md:ml-0  font-sans antialiased mds:text-[1.15rem] mds:leading-[3.4rem] md:leading-[3rem] s:text-[0.95rem] xss:text-[0.85rem] xss:leading-[1.54rem] tracking- [-0.01em] align-middle text-[#b24029]">
              Video title goes here inveni aut lt. <span className='inline-block float-right xsss:text-[2rem] xs:text-[1.6rem] xsss:font-extralight xs:font-thin'><button>&#8594;</button></span><span className='inline-block float-right xsss:text-[2rem] xs:text-[1.6rem] xsss:font-extralight xs:font-thin'>&nbsp;</span><span className='inline-block float-right xsss:text-[2rem] xs:text-[1.6rem] xsss:font-extralight xs:font-thin'><button>&#8592;</button></span>
              </p> */}
            </div>

            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]">
              <img
                src={sc_logo_two}
                className="w-[70px] mb-4 md:ml-7"
                alt="secret-circle-logo"
              />
              <h1 className="md:mb-14  xs:mb-0 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[392px] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem] align-middle text-[#b24029]">
                Maternity Mates
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking- [-0.01em] align-middle text-[#b24029]">
                This programme provides one-to-one pregnancy, labour and
                postnatal support. Local female volunteers are trained to
                provide vital practical and emotional support.<br></br>
                <br></br>We support women who find themselves alone, are
                refugees or new migrants, English is not a first language, who
                are homeless, living in refuges or temporary accommodation,
                survivors of domestic abuse, trafficked or have experienced
                forms of gender violence.
              </p>

              <div className="bg-[#eab1fc] xs:mb-[0vh] xs:px-4 md:px-0 pt-10 pb-[6rem] xs:mt-[5vh] md:mt-16  md:mb-0  xs:w-[100%] md:w-[389px] md:mx-[1.5rem]">
                <p className="xs:mt-0 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.2rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#b24029]">
                  Here are the contact details to self-refer<br></br>
                  or, alternatively,{" "}
                  <span>
                    <a
                      className="underline"
                      href="https://sistercirclelondon.typeform.com/to/nOg6I9GE"
                      rel="noreferrer"
                      target="_blank"
                    >
                      fill in this referral form:
                    </a>
                  </span>
                  <br></br>
                </p>
                <p className="xs:mt-[0.6rem] xs:mr-0 xs:ml-0 md:mt-[0.6rem] md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.2rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#b24029]">
                  Irantzu Pérez Arribas<br></br>
                  Head of Programmes<br></br>
                </p>
                <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-0 md:ml-7 block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full">
                  <a
                    href="mailto:Irantzu.Perez@sistercircle.org.uk"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Irantzu.Perez@sistercircle.org.uk
                  </a>
                </button>
                <button className="mt-3 xs:mr-0 xs:ml-0  md:mr-0 md:ml-7 block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full">
                  <a href="tel:079 6032 7760 ">079 6032 7760 </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Refer someone--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#ff794e]">
        <div className="mx-auto max-w-[1240px]">
          <div className="flex xs:flex-col-reverse md:flex-row items-center w-full bg-[#ff794e] md:max-h-[650px] overflow-hidden">
            <div className="md:mr-6 md:ml-7 md:mb-[18vh] md:mt-[18vh] xs:mb-[7rem] xs:mt-[5vh] xs:w-4/5 md:w-6/12 ">
              <img
                src={support_refer}
                className=" xs:mx-auto max-h-[312px] h-[100%]"
                alt="hero"
              />
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[7rem] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">
              <h1 className="md:mb-16 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-8 mds:ml-16 lgx:w-[352px] font-sans antialiased mds:text-[4.8rem] mds:leading-[4.2rem] s:text-[3rem] s:leading-[3rem] xss:text-[2.3rem] xss:leading-[2.3rem] align-middle text-[#ffe1d9]">
                Refer<br></br>someone
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-8 mds:ml-16 lgx:w-[376px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.654rem] tracking-[-0.01em] align-middle text-[#ffe1d9]">
                This is a private and confidential form used to refer women for
                Maternity Mates support, provided by Sister Circle.<br></br>
                <br></br>Responses to these questions will inform us whether the
                Maternity Mates programme can provide support to you / the
                pregnant woman.
              </p>
              <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-6 md:ml-8 mds:ml-16 block px-5 py-[0.4rem] xsss:text-lg xs:text-[0.9rem] xs:leading-7 font-medium text-center text-[#ffe1d9] border-2 border-[#ffe1d9] rounded-full">
                <a
                  href="https://sistercirclelondon.typeform.com/to/nOg6I9GE"
                  rel="noreferrer"
                  target="_blank"
                >
                  Make a referral to Maternity Mates{" "}
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Female Genital Cutting Support---------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={femaleComponent} className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px] md:pt-[7.4rem]">
          <div className="flex xs:flex-col md:flex-row xs:items-center md:items-start w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden">
            <div className=" xs:mb-[0vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">
              <h1 className="md:mb-14 xs:mb-0 xs:mt-12 xs:mr-0 xs:ml-0  md:mt-0 md:mr-0  md:ml-[4.5rem] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem]  align-middle text-[#b24029]">
                Female<br className="hidden md:block"></br> Genital Cutting
                Support
              </h1>
              <p className="xs:mt-4 mds:w-[360px] first-letter:xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-[4.5rem]  font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
                Holistic support for women experiencing health complications as
                a result of cutting: one-to-one advocacy, dedicated women’s
                clinic and counselling.<br></br>
                <br></br>
                Delivered by an all-female expert team of health advocates,
                consultant gynaecologists, councillors, interpreters and a
                specialist midwife.<br></br>
                <br></br>
                Languages: English and Somali<br></br>
                <br></br>
                The clinic is open on the first and third Wednesday of every
                month.
              </p>

              <div className="bg-[#b24029] xs:mb-[0vh] xs:px-4 md:px-0 pt-10 pb-[5rem] xs:mt-[5vh] md:mt-16  md:mb-0  xs:w-[100%] md:w-[389px] md:ml-[4.5rem] rounded-full rounded-l-lg max-w-[400px]">
                <p className="xs:mt-0 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.1rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#ffe1d9]">
                  Here are the contact details<br></br>
                  to refer you or someone<br></br>
                  you know:<br></br>
                  <br></br>
                  Amal Hassan<br></br>
                  Programme Coordinator<br></br>
                  <button className="mt-7 xs:mr-0 xs:ml-0  md:mb-4  block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#ffe1d9] border-2 border-[#ffe1d9] rounded-full">
                    <a
                      href="mailto:Amal.Hassan@sistercircle.org.uk"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Amal.Hassan@sistercircle.org.uk
                    </a>
                  </button>
                  <button className="mt-2 xs:mr-0 xs:ml-0  md:mb-4  block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#ffe1d9] border-2 border-[#ffe1d9] rounded-full">
                    <a href="tel:079 6032 7933">079 6032 7933</a>
                  </button>
                </p>
              </div>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" md:mr-0 md:mb-[0vh] md:mt-[0vh] xs:mb-[5vh] xs:mt-[5vh] xs:w-[90%] md:w-6/12 xs:ml-[10%] md:ml-0">
              <img
                src={support_femaleGC}
                className=" max-h-[671px] h-[auto]"
                alt="hero"
              />
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------Healing Conversations--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={healingComponent} className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px] xs:pt-[0vh] md:pt-[8rem] md:pb-[0vh]">
          <div className="flex xs:flex-col-reverse xs:items-center md:items-start md:flex-row w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden">
            {/* <div className="md:mb-[18vh] md:ml-7 md:mt-[18vh] xs:mb-[20vh] xs:mt-[5vh] xs:w-4/5 md:w-6/12 ">  */}
            <div className="   xs:mb-[5vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-[90%] md:w-[65%] xs:mr-[10%] md:mr-0 ">
              <img
                src={support_healing}
                className=" xs:mx-auto max-h-[671px] "
                alt="healing_image"
              />
            </div>

            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[0vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]">
              <h1 className="md:mb-14 xs:mb-0  xs:mt-12 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[392px] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem] align-middle text-[#b24029]">
                Healing Conversations
              </h1>
              <p className="xs:mt-4 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking- [-0.01em] align-middle text-[#b24029]">
                For women who have experienced Female Genital Cutting our Trauma
                Counselling service will provide a safe space with a counsellor
                to begin your healing journey.<br></br>
                <br></br>
                We can:<br></br>
                <br></br>
                <ul className="list-disc ml-[20px] mt-[-10px]">
                  <li className="pb-3">
                    Offer up to 20 dedicated<br></br>
                    counselling sessions
                  </li>

                  <li className="pb-3">
                    Conduct your session in English,<br></br>
                    Somalian, Arabic or French
                  </li>

                  <li>Offer sessions virtually or in person</li>
                </ul>
              </p>

              <div className="bg-[#eab1fc] xs:mb-[0vh] xs:px-4 md:px-0 pt-10 pb-[5rem] xs:mt-[5vh] md:mt-16  md:mb-0  xs:w-[100%] md:w-[389px] md:mx-[1.5rem]">
                <p className="xs:mt-0 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.2rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#b24029]">
                  Here are the contact details to self-refer<br></br>
                  or, alternatively,{" "}
                  <span>
                    <a
                      className="underline"
                      href="https://sistercirclelondon.typeform.com/counselling"
                      rel="noreferrer"
                      target="_blank"
                    >
                      fill in this referral form:
                    </a>
                  </span>
                  <br></br>
                </p>
                <p className="xs:mt-[0.6rem] xs:mr-0 xs:ml-0 md:mt-[0.6rem] md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.2rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#b24029]">
                  Ameena Rahman<br></br>
                  Head of Wellbeing<br></br>
                </p>
                <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-0 md:ml-7 block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full">
                  <a
                    href="mailto:Ameena.Rahman@sistercircle.org.uk"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Ameena.Rahman@sistercircle.org.uk
                  </a>
                </button>
                <button className="mt-3 xs:mr-0 xs:ml-0  md:mr-0 md:ml-7 block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full">
                  <a href="tel:079 0980 3728 ">079 0980 3728 </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Group Peer Support---------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={peerComponent} className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px] md:pt-[8rem]">
          <div className="flex xs:flex-col md:flex-row xs:items-center md:items-start w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden">
            <div className=" xs:mb-[0vh] xs:mt-[0vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">
              <h1 className="md:mb-14 xs:mb-0 xs:mt-12 xs:mr-0 xs:ml-0  md:mt-0 md:mr-0  md:ml-[4.5rem] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem] align-middle text-[#b24029]">
                Group Peer<br></br>Support
              </h1>
              <p className="xs:mt-4 mds:w-[360px] first-letter:xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-[4.5rem]  font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
                For women, who have experienced birth trauma or pregnancy loss.
                Our Group Peer Support provides safe local venues in Tower
                Hamlets and Waltham Forest for women to heal together through
                shared experiences. There are separate groups for each shared
                experience.
              </p>

              <div className="bg-[#b24029] xs:mb-[0vh] xs:px-4 md:px-0 pt-10 pb-[5rem] xs:mt-[5vh] md:mt-16  md:mb-0  xs:w-[100%] md:w-[389px] md:ml-[4.5rem] rounded-full rounded-l-lg max-w-[400px]">
                <p className="xs:mt-0 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.1rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#ffe1d9]">
                  Here are the contact details<br></br>
                  to self-refer or, alternatively,<br></br>
                  <span>
                    <a
                      className="underline"
                      href="https://sistercirclelondon.typeform.com/to/TxDsXJGF"
                      rel="noreferrer"
                      target="_blank"
                    >
                      fill in this referral form:
                    </a>
                  </span>
                  <br></br>
                  <br></br>
                  Alexa Henry<br></br>
                  Programme Coordinator<br></br>
                  <button className="mt-7 xs:mr-0 xs:ml-0  md:mb-4  block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#ffe1d9] border-2 border-[#ffe1d9] rounded-full">
                    <a
                      href="mailto:Alexa.Henry@sistercircle.org.uk"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Alexa.Henry@sistercircle.org.uk
                    </a>
                  </button>
                  <button className="mt-2 xs:mr-0 xs:ml-0  md:mb-4  block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#ffe1d9] border-2 border-[#ffe1d9] rounded-full">
                    <a href="tel:074 6032 7933">074 6032 7933</a>
                  </button>
                </p>
              </div>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" md:mr-0 md:mb-[0vh] md:mt-[0vh] xs:mb-[5vh] xs:mt-[3vh] xs:w-[90%] md:w-6/12 xs:ml-[10%] md:ml-0">
              <img
                src={support_peer}
                className=" max-h-[671px] h-[auto]"
                alt="hero"
              />
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------Women's Conversation Cafe--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={womensComponent} className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px] xs:pt-[0vh] md:pt-[8rem] md:pb-[10vh]">
          <div className="flex xs:flex-col-reverse xs:items-center md:items-start md:flex-row w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden">
            {/* <div className="md:mb-[18vh] md:ml-7 md:mt-[18vh] xs:mb-[20vh] xs:mt-[5vh] xs:w-4/5 md:w-6/12 ">  */}
            {/* <div className="   xs:mb-[5vh] xs:mt-[5vh] md:mt-0  md:mb-0 xs:w-full md:w-[65%] "> */}
            <div className="   xs:mb-[5vh] xs:mt-[5vh] md:mt-0  md:mb-0 xs:w-[90%] md:w-[65%] xs:mr-[10%] md:mr-0 ">
              <img
                src={support_womenConvo}
                className=" md:mx-auto xs:mx-0 max-h-[671px] "
                alt="healing_image"
              />
            </div>

            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[0vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]">
              <h1 className="md:mb-14  xs:mb-0 xs:mt-12 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[392px] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem] align-middle text-[#b24029]">
                Women’s<br></br>Conversation<br></br>Cafe
              </h1>
              <p className="xs:mt-4 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking- [-0.01em] align-middle text-[#b24029]">
                A safe space for women from diverse backgrounds to gather
                together to share experiences and hear from health experts. It
                is a space to improve their knowledge about how to access health
                care.
              </p>

              <div className="bg-[#eab1fc] xs:mb-[0vh] xs:px-4 md:px-0 pt-10 pb-[5rem] xs:mt-[5vh] md:mt-16  md:mb-0  xs:w-[100%] md:w-[389px] md:mx-[1.5rem]">
                <p className="xs:mt-0 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.2rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#b24029]">
                  Here are the contact details<br></br>
                  to refer you or someone you know:<br></br>
                </p>
                <p className="xs:mt-[0.6rem] xs:mr-0 xs:ml-0 md:mt-[0.6rem] md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.2rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#b24029]">
                  Amal Hassan<br></br>
                  Programme Coordinator<br></br>
                </p>
                <button className="mt-7 xs:mr-0 xs:ml-0  md:mr-0 md:ml-7 block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full">
                  <a
                    href="mailto:Amal.Hassan@sistercircle.org.uk"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Amal.Hassan@sistercircle.org.uk
                  </a>
                </button>
                <button className="mt-3 xs:mr-0 xs:ml-0  md:mr-0 md:ml-7 block px-5 py-[0.4rem] s:text-base xs:text-xs font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full">
                  <a href="tel:079 6032 7933">079 6032 7933</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------------------------------content 5 end--------------------------------------------------- */}
      <Footer />
    </div>
  );
};

export default Support;
